// @flow

import { navigate } from '@reach/router'
import { injectIntl } from 'gatsby-plugin-intl'
import { compose, withProps } from 'recompose'
import { formValueSelector, reduxForm } from 'redux-form'
import { connect } from 'react-redux'

/* $FlowFixMe: ignore */
import { encode } from 'querystring'
import Search from './Search'

const selector = formValueSelector('globalSearch') // <-- same as form name

export default compose(
  injectIntl,
  connect(state => ({
    globalQuery: selector(state, 'query') || ''
  })),
  withProps(ownProps => {
    return ({
      initialValues: {
        ...ownProps.searchValues
      },
      onSubmit: values => {
        if (values.query) {
          ownProps.onQueryChange(values.query)
        }
        setTimeout(() => {
          if (!values.query) {
            return
          }
          if (ownProps.withRedirect) {
            const url = `${ownProps.targetPage}?${encode(ownProps.createQueryParams(values))}`
            if (
              window.location.pathname.indexOf('/search') !== -1 || window.location.pathname.indexOf('/suche') !== -1
            ) {
              if (values.query) {
                window.location.href = url
              }
            } else {
              navigate(
                url,
                { replace: true }
              )
            }
          }
        }, 100)
      },
      form: ownProps.form || 'searchQuery'
    })
  }),
  reduxForm({
    enableReinitialize: true,
    destroyOnUnmount: false
  }),
)(Search)
