// @flow

const isCacheEnabled = true // process.env.NODE_ENV !== 'development'
const isBrowser = typeof window !== "undefined"

const getSessionStorageProvider = () => {
  return isBrowser ? window.sessionStorage : null
}

const getLocalStorageProvider = () => {
  return isBrowser ? window.localStorage : null
}

export const cacheFactory = (provider: any, cacheEnabled: boolean = isCacheEnabled) => {
  const set = (variable: string, value: any, ttl: number = 86400) => {
    if (!isBrowser) {
      return null
    }
    const data = { value, expires_at: new Date().getTime() + ttl }
    provider.setItem(variable, JSON.stringify(data))
  }

  const get = (variable: string, forceEnabled?: boolean) => {
    if (!isBrowser) {
      return null
    }

    if (!cacheEnabled && !forceEnabled) {
      return null
    }
    const content = provider.getItem(variable)
    if (content === null) {
      return null
    }
    // $FlowFixMe: ignore
    const data = JSON.parse(content)
    if (data !== null) {
      if (data.expires_at !== null && data.expires_at < new Date().getTime()) {
        provider.removeItem(variable.toString())
      } else {
        return data.value
      }
    }
    return null
  }
  return {
    set,
    get
  }
}

export const sessionCache = cacheFactory(getSessionStorageProvider())
export const localCache = cacheFactory(getLocalStorageProvider())
