// @flow

import React from 'react'
import classNames from 'classnames'
import { applyStyling } from '../../lib/styling'
import { Container } from '../../components/basic/Grid'
import Logo from './components/Logo'
import Subnav from './components/Subnav'
import Mainnav from './components/Mainnav'
import baseStyles from './Header.scss'

type Props = {
  styles: Object,
  withTeaser?: Boolean,
  isLoading?: Boolean,
  meta: Object
}

const Header = ({ styles, withTeaser, meta, isLoading }: Props) => (
  <header className={classNames(styles.root, withTeaser && styles['is-withTeaser'])}>
    <div className={styles.innerWrap}>
      <Container className={styles.container}>
        <Logo />
        <div className={styles.nav}>
          <Subnav meta={meta} isLoading={isLoading}/>
          <Mainnav />
        </div>
      </Container>
    </div>
  </header>
)

export default applyStyling(baseStyles)(Header)
