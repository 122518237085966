// @flow
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { applyStyling } from '../../lib/styling'
import { StaticQuery, graphql } from 'gatsby'
import AcceptCookies from '../AcceptCookies'

import '../../styles/base.scss'
import baseStyles from './Layout.scss'

type Props = {
  styles: Object,
  isSecondary?: Boolean,
  isLoading?: Boolean,
  children?: any,
  footer?: any
}

const Layout = ({ styles, isSecondary, children, footer, isLoading, ...rest }: Props) => {
  return (
    <StaticQuery
      query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
      render={data => (
        <>
          <div
            className={
              classNames(styles.root, isSecondary && styles['is-secondary'], isLoading ? styles.isLoading : '')
            }
          >
            <div className={styles.content}>
              {children}
            </div>
            {footer}
            <AcceptCookies isLoading={isLoading}/>
          </div>
        </>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default applyStyling(baseStyles)(Layout)
