// @flow

import React, { useCallback } from 'react'
import { Field, Form } from 'redux-form'
import { applyStyling } from '../../lib/styling'
import Device from '../../components/basic/Device'
import Button from '../../components/basic/Button'
import { GenericInputField } from '../../components/basic/Forms/Fields'
import baseStyles from './Search.scss'

type Props = {
  styles: Object,
  searchOpen: boolean,
  handleSubmit: Function,
  onBtnClick?: Function,
  queryPropName?: string,
  form: string,
  globalQuery: string
}

const Search = ({
  styles, searchOpen = true, onBtnClick, handleSubmit, queryPropName = 'query', form, globalQuery
}: Props) => {
  const onClick = useCallback(() => {
    if (searchOpen && form === 'globalSearch') {
      if (globalQuery) {
        handleSubmit()
      } else {
        handleSubmit()
        onBtnClick && onBtnClick()
      }
    } else {
      if (searchOpen && form !== 'globalSearch') {
        handleSubmit()
      } else {
        onBtnClick && onBtnClick()
      }
    }
  }, [ searchOpen, onBtnClick, handleSubmit, globalQuery ])

  return (
    <div className={styles.root}>
      <Form onSubmit={handleSubmit}>
        <Field
          name={queryPropName}
          component={GenericInputField}
          className={styles.input}
          placeholder="Suchen"
          required
        />
        <Device phone>
          <Button
            styles={styles.searchMobileBtn}
            icon="icon-search"
            color="link"
            type="button"
            onClick={onClick}
          />
        </Device>
        <Device tablet desktop>
          <Button
            styles={styles.searchBtn}
            icon="icon-search"
            color="link"
            onClick={onClick}
          />
        </Device>
      </Form>
    </div>
  )
}

export default applyStyling(baseStyles)(Search)
