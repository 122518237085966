// @flow

import React from 'react'
import classNames from 'classnames'
import { applyStyling } from '../../lib/styling'
import { Container, Row, Col } from '../../components/basic/Grid'
import Accordion from '../../components/basic/Accordion'
import Collapsible from '../../components/basic/Collapsible'
import Device from '../../components/basic/Device'
import paws from '../../images/illustration/paws-01.svg'
import paws2 from '../../images/illustration/paws-02.svg'
import baseStyles from './Footer.scss'

type Props = {
  styles: Object,
  meta: Object
}

const Footer = ({
  styles,
  meta
}: Props) => {
  const footerItems = meta?.footer || []

  return (
    <footer className={styles.root}>
      <img className={classNames(styles.paws, styles.pawsLeft)} src={paws}/>
      <img className={classNames(styles.paws, styles.pawsRight)} src={paws2} />
      <Container className={styles.container}>
        <Device tablet desktop>
          <Row>
            {footerItems.map(({ title, content }, key) => (
              <Col md="4" key={key}>
                <h3 className={styles.title}>{title}</h3>
                <div dangerouslySetInnerHTML={{ __html: content }}/>
              </Col>
            ))}
          </Row>
        </Device>

        <Device phone>
          <Accordion initiallyOpenedKey="1">
            {footerItems.map(({ title, content }, key) => (
              <Collapsible
                key={key}
                styles={styles.collapsible}
                eventKey={`${key}`}
                trigger={`${title}`}
              >
                <div dangerouslySetInnerHTML={{ __html: content }}/>
              </Collapsible>
            ))}
          </Accordion>
        </Device>
      </Container>
    </footer>
  )
}

export default applyStyling(baseStyles)(Footer)
