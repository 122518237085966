// @flow

import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby-plugin-intl'
import { applyStyling } from '../../../../lib/styling'
import Device from '../../../../components/basic/Device'
import Search from '../../../../components/Search'
import OffCanvas from '../../../../components/basic/OffCanvas'
import Collapsible from '../../../../components/basic/Collapsible'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from '../../../../components/basic/Dropdown'
import baseStyles from './Mainnav.scss'
import withQueryWrapper from '../../../../state/enchancers/globalSearch/withQueryWrapper'
import LocationContext from '../../../../lib/locationContext'
// import cache from '../../../../lib/cache'

type Props = {
  styles: Object,
  url: string,
  menu: Array<Object>
}

// REF: handling dropdown states should be optimized
type State = {}

class Mainnav extends React.Component<Props, State> {
  state = {}
  handlers = {}

  toggleDropdown = (key: string) => {
    if (this.handlers[key]) {
      return this.handlers[key]
    }
    this.handlers[key] = () => {
      this.setState({
        [`dropdownOpen${key}`]: !this.state[`dropdownOpen${key}`]
      })
    }
    return this.handlers[key]
  }

  render() {
    const { styles, url, menu } = this.props

    const pages = menu

    const MenuOffcanvas = ({ children }: Object) => (
      <OffCanvas
        styles={styles.offcanvas}
        customBurgerIcon="icon-menu"
        customCrossIcon="icon-close-2"
        side="right"
        title="Menü"
      >
        {children}
      </OffCanvas>
    )

    const ChildNodesComponent = () => (
      <ul className={styles.navList}>
        {pages.map((page, index) => (
          page.subPages ?
            <li className={styles.navItem} key={index}>
              <Device tablet desktop>
                <Dropdown isOpen={this.state[`dropdownOpen${page.id}`]} toggle={this.toggleDropdown(page.id)}>
                  <DropdownToggle nav tag="div" className={styles.navDropdown}>
                    <span className={styles.navText}>{page.name}</span>
                  </DropdownToggle>
                  <DropdownMenu>
                    {page.subPages.map((subpage, index) => (
                      <DropdownItem key={index} tag="div" className={styles.navDropdownItem}>
                        <Link
                          to={`/${subpage.slug}`}
                          className={styles.navDropdownLink}
                          activeClassName={styles['is-active']}
                        ><span dangerouslySetInnerHTML={{ __html: subpage.name }}/></Link>
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </Dropdown>
              </Device>
              <Device phone>
                <Collapsible
                  styles={styles.collapsible}
                  trigger={<div className={styles.navText}>{page.name}</div>}
                  isInitiallyOpen={page.subPages.some(item => url.match(new RegExp(`${item.slug}$`)))}
                >
                  <ul className={styles.navList}>
                    {page.subPages.map((subpage, index) => (
                      <li className={styles.navItem} key={index}>
                        <Link to={`/${subpage.slug}`} className={styles.navLink} activeClassName={styles['is-active']}>
                          <span className={styles.navText}>{subpage.name}</span>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </Collapsible>
              </Device>
            </li> :
            <li className={styles.navItem} key={index}>
              <Link to={`/${page.slug}`} className={styles.navLink} activeClassName={styles['is-active']}>
                <span className={styles.navText}>{page.name}</span>
              </Link>
            </li>
        ))}
      </ul>
    )

    const SearchComponent = withQueryWrapper(props => (
      <Search
        styles={styles.search}
        searchOpen
        withRedirect
        searchValues={props.globalSearch}
        targetPage="suche"
        {...props}
      />
    ))

    return (
      <nav className={styles.root}>
        <Device tablet desktop>
          <ChildNodesComponent />
        </Device>
        <Device phone>
          <MenuOffcanvas>
            <div className={styles.searchWrap}>
              <SearchComponent form="globalSearch"/>
            </div>
            <ChildNodesComponent />
          </MenuOffcanvas>
        </Device>
      </nav>
    )
  }
}

const WrappedMainnav = ({ url, ...rest }: Props) => (
  <LocationContext.Consumer>
    { location => <Mainnav {...rest} url={url || location.href}/>}
  </LocationContext.Consumer>
)

const mapMenu = (items, pid = 0, parentMenuPath = '') => {
  const result = []
  for (let item of items) {
    const menuPath = `${parentMenuPath}-${item['ID']}`
    result.push({
      id: item['ID'],
      parentId: pid,
      name: item['title'],
      objectType: item['object'],
      objectId: item['object_id'],
      menuPath: menuPath,
      slug: item['slug'],
      link: item['link'],
      ...(item['child_items'] ? { subPages: mapMenu(item['child_items'], item['ID'], menuPath) } : {})
    })
  }
  return result
}

export const withMenu = (WrappedComponent: any) => {
  const MenuAwareComponent = (props: Object) => {
    const [ menu, setMenu ] = useState([])
    useEffect(() => {
      const menuUrl = '/wp-json/menus/v1/menus/main_menu'
      const value = false // cache.get(menuUrl, true)
      if (value) {
        setMenu(value)
      } else {
        fetch(menuUrl)
          .then(data => data.json())
          .then(result => {
            if (result) {
              const value = mapMenu(result.items)
              setMenu(value)
              // cache.set(menuUrl, value)
            }
          })
      }
    }, [])

    return (
      <WrappedComponent {...props} menu={menu}/>
    )
  }

  return MenuAwareComponent
}

export default applyStyling(baseStyles)(withMenu(WrappedMainnav))
