// @flow
import React, { useContext } from 'react'
/* eslint-disable react/display-name */

/* $FlowFixMe: ignore */
const LocationContext = React.createContext(null)

export const withLocationProvider = (WrappedComponent: any) => (props: Object) => (
  <LocationContext.Provider value={props.location}>
    <WrappedComponent {...props} />
  </LocationContext.Provider>
)

export const withLocation = (WrappedComponent: any) => (props: Object) => {
  const location = useContext(LocationContext)
  return (
    <WrappedComponent {...props} location={location}/>
  )
}

export default LocationContext
