import React, { memo } from 'react'
import Slider from '@fcse/tsbob/dist/components/Carousel'
import { compose, withStateHandlers } from 'recompose'

const WrappedSlider = ({
  dragging,
  isDragging,
  isNotDragging,
  currentIndex,
  children,
  settings,
  ...rest
}: Object) => (
  <Slider
    settings={{
      beforeChange: isDragging,
      afterChange: isNotDragging,
      ...settings
    }}
    {...rest}
  >
    {typeof children === 'function'
      ? children(dragging, currentIndex)
      : children}
  </Slider>
)

export default compose(
  memo,
  withStateHandlers(
    (props: Object) => ({
      dragging: false,
      currentIndex: props.initialSlide || 0
    }),
    {
      isDragging: () => (currentIndex: number, nextIndex: number) => ({
        dragging: true,
        currentIndex: nextIndex
      }),
      isNotDragging: () => () => ({ dragging: false })
    }
  )
)(WrappedSlider)
