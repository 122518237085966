// @flow

import { useEffect, useRef, useState } from 'react'
import { isClient, on, off } from './util'

export type State = {
  x: number,
  y: number
}

const useWindowScroll = (): State => {
  const frame = useRef(0)
  const [ state, setState ] = useState<State>({
    x: isClient ? global.window.pageXOffset : 0,
    y: isClient ? global.window.pageYOffset : 0
  })

  useEffect(() => {
    const handler = () => {
      /* $FlowFixMe: ignore */
      cancelAnimationFrame(frame.current)
      frame.current = requestAnimationFrame(() => {
        setState({
          x: global.window.pageXOffset,
          y: global.window.pageYOffset
        })
      })
    }

    on(global.window, 'scroll', handler, {
      capture: false,
      passive: true
    })

    return () => {
      /* $FlowFixMe: ignore */
      cancelAnimationFrame(frame.current)
      off(global.window, 'scroll', handler)
    }
  }, [])

  return state
}

export default useWindowScroll
