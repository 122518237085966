// @flow
import { Component } from 'react'
import { scrollToTarget } from 'helpers/scroll'

type Props = {
  globalSearch: Object,
  updateStateValue: (path: string, value: any) => void,
  children: (props: WithGlobalSearchHandlersProps) => any
}

export type GlobalSearchHandlers = {
  onQueryChange: Function,
  onPageChange: Function,
  onPerPageChange: Function
}

export type WithGlobalSearchHandlersProps = GlobalSearchHandlers & {
  globalSearch: Object
}

class WithHandlersWrapper extends Component<Props> {
  onPageChange = (perPage: string) => {
    this.props.updateStateValue('page', perPage)
    scrollToTarget('filter-mask')
  }

  onPerPageChange = (perPage: string) => {
    this.props.updateStateValue('perPage', perPage)
  }

  onQueryChange = (query: string) => {
    this.props.updateStateValue('page', 0)
    this.props.updateStateValue('query', query)
  }

  render() {
    const { globalSearch, children } = this.props
    return children({
      globalSearch,
      onPageChange: this.onPageChange,
      onPerPageChange: this.onPerPageChange,
      onQueryChange: this.onQueryChange
    })
  }
}

export default WithHandlersWrapper
