// @flow

import React, { useContext, type AbstractComponent } from 'react'
import { QueryContainer } from 'react-history-query'
import { createBrowserHistory, createMemoryHistory } from 'history'
import { GlobalStateProvider } from '@fcse/param-converter'
import { Form as GlobalSearch } from '../state/globalSearch'
import LocationContext from '../lib/locationContext'
import _get from 'lodash/get'

export const withSearch = <Props: {}>(
  Component: AbstractComponent<Props>
): AbstractComponent<Props> => {
  return function WrapperComponent(props: Props) {
    const location = useContext(LocationContext)
    const history = (typeof window === 'undefined') ?
      createMemoryHistory({ initialEntries: [ _get(location, 'pathname') ] }) :
      createBrowserHistory()

    return (
      <QueryContainer history={history}>
        <GlobalStateProvider>
          <GlobalSearch>
            <Component {...props} />
          </GlobalSearch>
        </GlobalStateProvider>
      </QueryContainer>
    )
  }
}
