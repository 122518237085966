// @flow

import React from 'react'
import { applyStyling } from '../../lib/styling'
import baseStyles from './AcceptCookies.scss'
import Button from '../../components/basic/Button'
import { Link } from 'gatsby-plugin-intl'
import Modal from '../basic/Modal/index'
import { Container, Row, Col } from '../../components/basic/Grid'
import CheckboxSwitch from '../../components/basic/CheckboxSwitch'
import { useAcceptCookies } from 'components/AcceptCookies/hook'

type Props = {
  styles: Object,
  trigger?: boolean,
  triggerLabel?: any,
  triggerClass?: any,
  onMapAccept?: Function,
  isLoading: boolean
}

const AcceptCookies = ({ styles, isLoading, trigger, triggerLabel, onMapAccept, triggerClass }: Props) => {
  const {
    doTrigger,
    visible,
    onCancel,
    onConfirm,
    onConfigure,
    isConfigure,
    onAcceptAll,
    onChangePiwik,
    isPiwikOn,
    onChangeMapbox,
    isMapboxOn,
    onBack
  } = useAcceptCookies({ onMapAccept, trigger })

  return (
    <>
      {trigger &&
        <div className={triggerClass} onClick={doTrigger}>{triggerLabel}</div>
      }
      {visible && !isLoading
        ? (
          <Modal
            title="Cookies anpassen"
            visible={visible}
            closeButton
            closeOnOverlayClick={true}
            onClose={onCancel}
            styles={styles.modal, styles.cookiesmodal}
            footer={
              <Container styles={styles.containerfooter}>
                <Row>
                  <Col xs="6" md="6">
                    <Button
                      type="button"
                      styles={styles.btn, styles.btnDeclined}
                      onClick={isConfigure ? onBack : onCancel}
                      outline
                    >
                      Ablehnen
                    </Button>
                  </Col>
                  <Col xs="6" md="3">
                    {isConfigure ? (
                      <Button
                        type="button"
                        styles={styles.btn, styles.btnadjust}
                        onClick={onConfirm}
                        outline
                      >
                        Bestätigen
                      </Button>
                    ) : (
                      <Button
                        type="button"
                        styles={styles.btn, styles.btnadjust}
                        onClick={onConfigure}
                        outline
                      >
                        Anpassen
                      </Button>
                    )}
                  </Col>
                  <Col xs="12" md="3">
                    <Button
                      type="button"
                      styles={styles.btn}
                      className={styles.btnAcceptAll}
                      onClick={onAcceptAll}
                    >
                      Alle zulassen
                    </Button>
                  </Col>
                </Row>
              </Container>
            }
          >
            {isConfigure ? (
              <div>
                <Row>
                  <Col md="6" className={styles.cookieName}>Piwik</Col>
                  <Col md="6" className={styles.right}>
                    <CheckboxSwitch onChange={onChangePiwik} checked={isPiwikOn}/>
                  </Col>
                </Row>
                <div>
                  Mit dieser Software können wir das Online-Verhalten in Bezug auf Zeit, geografische Lage,
                  Art und Betriebssystem des verwendeten Gerätes, verwendeter Browser und Nutzung dieser
                  Webseite analysieren.
                </div>
                <hr/>
                <Row>
                  <Col className={styles.cookieName}>Mapbox</Col>
                  <Col md="6" className={styles.right}>
                    <CheckboxSwitch onChange={onChangeMapbox} checked={isMapboxOn}/>
                  </Col>
                </Row>
                <div>
                  Mapbox ist ein Unternehmen, welches benutzerdefinierte Karten für Webseiten anbietet.
                  Durch die Nutzung dieser Funktionen, werden unter anderem IP-Adressen an Mapbox weitergeleitet.
                </div>
              </div>
            ) :
              (<div>
                <div className={styles.cookieName}>Diese Webseite verwendet Cookies</div>
                Wir verwenden Cookies, um Inhalte und Anzeigen zu personalisieren,
                Funktionen für soziale Medien anbieten
                zu können und die Zugriffe auf unsere Website zu analysieren. Unsere Partner führen diese Informationen
                möglicherweise mit weiteren Daten zusammen, die Sie ihnen bereitgestellt haben oder die sie im Rahmen
                Ihrer Nutzung der Dienste gesammelt haben.
                <br/><br/>
                Mehr Informationen erhalten Sie <Link to={'/datenschutz'} onClick={onCancel}>hier</Link>.
              </div>)
            }
          </Modal>
      /*
      <div className={styles.root}>
          Diese Website verwendet Cookies, um Ihre Erfahrung auf unserer Website zu verbessern.
          Wenn Sie fortfahren, stimmen Sie der Verwendung unserer Cookies zu.
          <div className={styles.btnWrap}>
            <Link to={'/datenschutz'}>
              <Button
                type="button"
                styles={styles.btn}
              >
                Mehr erfahren
              </Button>
            </Link>

            <Button
              type="button"
              styles={styles.btn}
              onClick={onAccept}
            >
              Bestätigen
            </Button>
          </div>
        </div>*/
        )
        : null}
    </>
  )
}

export default applyStyling(baseStyles)(AcceptCookies)
