// @flow

import { pm, serializers, queryFormFactory } from '@fcse/param-converter'

const config = {
  query: pm('query', serializers.string),
  perPage: pm('perPage', serializers.number),
  page: pm('page', serializers.number)
}

export const defaultValues = {
  query: '',
  page: 0,
  perPage: 10
}

const { Form, FormWithQuery, withQuery, createQueryString }
  = queryFormFactory('globalSearchNS', () => defaultValues, config, null, 'gs') // in case we need a separate namespace


export { Form, FormWithQuery, withQuery, createQueryString }
