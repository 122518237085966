import { useState, useCallback, useEffect } from 'react'

import useModalActions from '../../hooks/modalActions'
import { localCache } from '../../lib/cache'
const COOKIE_NAME_PIWIK = 'accept_cookies_piwik'

export const COOKIE_NAME_MAPBOX = 'accept_cookies_mapbox'
export const COOKIE_VALUE_ACCEPT = 'yes'
const COOKIE_VALUE_REJECT = 'no'
const COOKIE_LIFETIME_DAYS = 365 * 86400
const SESSION_KEY = 'cookies_processed'

export const useAcceptCookies = ({ onMapAccept, trigger }) => {
  const [ processed, setProcessed ] = useState(Boolean(localCache.get(SESSION_KEY)))
  const onAcceptPiwik = useCallback((value = COOKIE_VALUE_ACCEPT) => {
    if (value === COOKIE_VALUE_ACCEPT && window) {
      window._paq = window._paq || []
      window._paq.push([ 'setConsentGiven' ])
      window._paq.push([ 'setCookieConsentGiven' ])
    }
    localCache.set(COOKIE_NAME_PIWIK, value, COOKIE_LIFETIME_DAYS)
  })
  const onAcceptMapbox = useCallback((value = COOKIE_VALUE_ACCEPT) => {
    localCache.set(COOKIE_NAME_MAPBOX, value, COOKIE_LIFETIME_DAYS)
    if (onMapAccept && value === COOKIE_VALUE_ACCEPT) {
      onMapAccept(true)
    }
  })

  const [ isConfigure, setIsConfigure ] = useState(false)
  const { visible, closeModal, openModal } = useModalActions()

  const onCancel = useCallback(() => {
    localCache.set(SESSION_KEY, '1')
    setProcessed(true)
    closeModal()
  }, [ closeModal ])

  const onConfigure = useCallback(() => {
    setIsConfigure(true)
  }, [ setIsConfigure ])

  const onBack = useCallback(() => {
    setIsConfigure(false)
  }, [ setIsConfigure ])

  const doTrigger = useCallback(() => {
    setIsConfigure(false)
    openModal()
  }, [ setIsConfigure, openModal ])

  const [ isPiwikOn, setIsPiwikOn ] = useState(true)
  const [ isMapboxOn, setIsMapboxOn ] = useState(true)

  const onChangePiwik = useCallback(() => setIsPiwikOn(!isPiwikOn), [ setIsPiwikOn, isPiwikOn ])
  const onChangeMapbox = useCallback(() => setIsMapboxOn(!isMapboxOn), [ isMapboxOn, setIsMapboxOn ])
  const onConfirm = useCallback(() => {
    onAcceptPiwik(isPiwikOn ? COOKIE_VALUE_ACCEPT : COOKIE_VALUE_REJECT)
    if (!isPiwikOn) {
      window._paq = window._paq || []
      window._paq.push([ 'forgetConsentGiven' ])
      window._paq.push([ 'forgetCookieConsentGiven' ])
    }
    onAcceptMapbox(isMapboxOn ? COOKIE_VALUE_ACCEPT : COOKIE_VALUE_REJECT)
    setProcessed(true)
    closeModal()
  }, [ isPiwikOn, isMapboxOn ])

  const onAcceptAll = useCallback(() => {
    setIsPiwikOn(true)
    setIsMapboxOn(true)
    onAcceptPiwik()
    onAcceptMapbox()
    setProcessed(true)
    closeModal()
  }, [ onAcceptPiwik, onAcceptMapbox, setProcessed, closeModal, setIsPiwikOn, setIsMapboxOn ])

  useEffect(() => {
    if (!localCache.get(COOKIE_NAME_PIWIK) && !localCache.get(COOKIE_NAME_MAPBOX) && !processed && !trigger) {
      openModal()
    }
  }, [ processed, trigger ])

  return {
    doTrigger, visible, onCancel, onConfirm, onConfigure, onBack, isConfigure, onAcceptAll, onChangePiwik,
    isPiwikOn, onChangeMapbox, isMapboxOn
  }
}
