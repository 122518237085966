/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import { shorten } from 'helpers/misc'

function SEO({ description, lang, meta, keywords, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const wordpressUrl = process.env.WORDPRESS_URL || 'https://wordpress.tierheim-duesseldorf.de'

  const metaDescription = description || site.siteMetadata.description
  const thisTitle = title || site.siteMetadata.title
  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={shorten(thisTitle, 999)}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      link={[
        {
          rel: 'stylesheet',
          href: `${wordpressUrl}/wp-content/themes/twentyseventeen/assets/css/blocks.css?ver=20190105`
        },
        {
          rel: 'stylesheet',
          href: `${wordpressUrl}/wp-includes/css/dist/block-library/style.min.css?ver=5.4.1`
        }/* ,
        {
          rel: 'stylesheet',
          href: `${wordpressUrl}/wp-content/themes/twentyseventeen/style.css?ver=20190507`
        }*/
      ]}
      meta={[
        {
          name: 'description',
          content: metaDescription
        },
        {
          property: 'og:title',
          content: title
        },
        {
          property: 'og:description',
          content: metaDescription
        },
        {
          property: 'og:type',
          content: 'website'
        },
        {
          name: 'twitter:card',
          content: 'summary'
        },
        {
          name: 'twitter:creator',
          content: site.siteMetadata.author
        },
        {
          name: 'twitter:title',
          content: title
        },
        {
          name: 'twitter:description',
          content: metaDescription
        }
      ]
        .concat(
          keywords.length > 0
            ? {
              name: 'keywords',
              content: keywords.join(', ')
            }
            : []
        )
        .concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: 'de',
  meta: [],
  keywords: [],
  description: ''
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired
}

export default SEO
