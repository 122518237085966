// @flow
import React from 'react'
import { applyStyling } from '../../../../lib/styling'
import { Link } from 'gatsby-plugin-intl'
import LogoSrc from '../../../../images/logo/logo.svg'
import baseStyles from './Logo.scss'

type Props = {
  styles: Object,
  serverSettings: Object
}

const Logo = ({ styles }: Props) => (
  <Link to="/" className={styles.root}>
    <img src={LogoSrc} className={styles.img} alt="" />
    <h1 className={styles.title}>
      Tierschutzverein
      <span className={styles.locale}>Düsseldorf e.V.</span>
    </h1>
  </Link>
)

export default applyStyling(baseStyles)(Logo)
