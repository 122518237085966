// @flow

import React, { PureComponent } from 'react'
import Button from '../Button'
import { FlyoutInput } from '../../basic/Flyout'
import { DatePicker } from '../../basic/DatePicker'
import { applyStyling } from '../../../lib/styling'
import baseStyles from './EnhancedDatePicker.scss'
import classNames from 'classnames'
import { Field } from 'redux-form'
import { InputField } from '../../basic/Forms/Fields'
import moment from 'moment'

type Props = {
  styles: Object,
  calendarProps: Object,
  inputProps: Object,
  onChange: Function,
  value: string,
  name: string,
  labelCloseButton: string,
  labelSubmitButton: string,
  dateFormat: string
}

class EnhancedDatePicker extends PureComponent<Props> {
  static defaultProps = {
    dateFormat: 'DD/MM/YYYY'
  }

  headerRenderer = () => null

  bodyRenderer = (widgetProps, bodyProps) => {
    const { styles } = this.props
    return (
      <DatePicker
        required
        showWeekNumbers
        hideOnDayClick={false}
        {...widgetProps}
        styles={styles.datepicker}
      />
    )
  }

  footerRenderer = (widgetProps: Object, footerProps: Object, events: Object) => {
    const { styles, labelCloseButton, labelSubmitButton } = this.props
    return (
      <div className={styles.footer}>
        <Button outline color="gray-dark" styles={styles.modalBtn} onClick={events.onClose}>{labelCloseButton}</Button>
        <Button type="button" onClick={events.onSubmit}>{labelSubmitButton}</Button>
      </div>
    )
  }

  triggerRenderer = () => {
    const { styles, name, inputProps } = this.props
    return (
      <div className={styles.trigger}>
        <Field
          name={name}
          component={InputField}
          hideError
          autoComplete="off"
          {...inputProps}
        />
        <span className={classNames(styles.triggerIcon, 'icon-calendar')} />
      </div>
    )
  }

  onChange = value => {
    const { onChange, dateFormat } = this.props
    const dt = moment(value).format(dateFormat)
    onChange(dt)
  }

  render() {
    const { styles, value, calendarProps, ...rest } = this.props
    const momentValue = moment(value)
    const parsedValue = momentValue.isValid() ? momentValue.toDate() : ''
    return (
      <FlyoutInput
        value={parsedValue}
        styles={{ flyout: styles.flyout }}
        triggerRenderer={this.triggerRenderer}
        placement="bottom"
        bodyRenderer={this.bodyRenderer}
        footerRenderer={this.footerRenderer}
        headerRenderer={this.headerRenderer}
        widgetProps={{
          ...calendarProps,
          styles: styles.datepicker
        }}
        {...rest}
        onChange={this.onChange}
      />
    )
  }
}

export default applyStyling(baseStyles)(EnhancedDatePicker)
