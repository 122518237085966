import { useCallback, useMemo, useState } from 'react'

export type State = {
  visible: boolean,
  closeModal: Function,
  openModal: Function
}

const useModalActions = (initStatus = false): State => {
  const [ visible, setVisible ] = useState(initStatus)

  const closeModal = useCallback(() => setVisible(false), [ visible ])
  const openModal = useCallback(() => setVisible(true), [ visible ])

  return useMemo(() => ({
    visible,
    closeModal,
    openModal
  }), [ visible ])
}

export default useModalActions
