// @flow
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

export const findTreeNode = (
  tree: Array<Object>,
  value: number,
  prop: string = 'value',
  subTreeProp: string = 'children'
): ?Object => {
  for (let i = 0; i < tree.length; ++i) {
    if (tree[i][prop] === value) {
      return tree[i]
    }
    const found = findTreeNode(tree[i][subTreeProp] || [], value)
    if (found) {
      return found
    }
  }
  return null
}

export const findOptionByValue = (options: Array<any> = [], value: any) => {
  if (value) {
    const filtered = options.filter(option => option.value === value)
    if (filtered.length > 0) {
      return filtered[0]
    }
  }
  return null
}

export const pickProps = (componentProps: Object, names: Array<string>) => {
  return names.reduce(
    (props, name) => Object.assign({}, props, { [name]: componentProps[name] }),
    {}
  )
}

export const omitProps = (componentProps: Object, names: Array<string>) => {
  return Object.keys(componentProps).reduce(
    (props, name) =>
      names.indexOf(name) === -1
        ? Object.assign({}, props, { [name]: componentProps[name] })
        : props,
    {}
  )
}

let popover
const observers = []

// mutationObserver solution was picked to overcome scrolling issue on iOS
// body-scroll-lock locks select menu scrolling in flyouts
// so mutationObserver checks when menu is added to DOM
// and then uses it to lock just body scrolling

const isSelectMenuNode = (node: any) =>
  node && node.classList.contains('Select-menu-outer')

const setMutationObserver = (targets: Array<any>) => {
  if (!MutationObserver) {
    return
  }
  const config = { attributes: true, childList: true, characterData: true }
  targets.forEach(target => {
    const observer = new MutationObserver(mutations => {
      mutations.forEach((mutation: Object) => {
        if (isSelectMenuNode(mutation.addedNodes[0])) {
          const selectMenu = mutation.addedNodes[0].getElementsByClassName(
            'Select-menu'
          )[0]
          enableBodyScroll(popover)
          disableBodyScroll(selectMenu)
        } else if (isSelectMenuNode(mutation.removedNodes[0])) {
          disableBodyScroll(popover)
        }
      })
    })

    observer.observe(target, config)
    observers.push(observer)
  })
}

export const onFlyoutOpen = (isMobile: boolean) => {
  if (!isMobile) {
    return
  }
  const flyoutBody = document.querySelector('.flyout--body')
  popover = document.querySelector('.popover')
  if (flyoutBody && popover) {
    disableBodyScroll(popover)
    const selects = flyoutBody.getElementsByClassName('Select')
    setMutationObserver([].slice.call(selects))
  }
}

export const onFlyoutClose = (isMobile: boolean) => {
  if (!isMobile) {
    return
  }
  enableBodyScroll(popover)
  observers.forEach(observer => {
    if (observer && observer.disconnect) {
      observer.disconnect()
    }
  })
}

export const highlight = (str: string, keyword: string) =>
  keyword
    ? str.replace(
      new RegExp(keyword, 'ig'),
      match => '<strong>' + match + '</strong>'
    )
    : str

export const scrollIntoView = (
  element: ?HTMLElement,
  smooth: boolean = true
) => {
  if (element) {
    element.scrollIntoView(
      smooth ? { behavior: 'smooth', block: 'start' } : undefined
    )
  }
}


export const shorten = (
  content: string, maxLen: number, stripTags: boolean = true, separator: string = ' ', suffix: string = '...'
) => {
  const str = stripTags ?
    content
      .replace(/(<([^>]+)>)/ig, '')
      .replace(/&#\d+;/g, ' ')
      .replace(/&[a-zA-Z]+;/g, ' ') :
    content
  if (str.length <= maxLen) return str
  return str.substr(0, str.lastIndexOf(separator, maxLen)) + suffix
}

export const getParameterByName = (name: string, url: string) => {
  const normalizedName = name.replace(/[[\]]/g, '\\$&')
  const regex = new RegExp('[?&]' + normalizedName + '(=([^&#]*)|&|#|$)')
  const results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}
