/** @flow */
import React from 'react'
import classNames from 'classnames'
import { injectIntl } from 'gatsby-plugin-intl'
import { applyStyling } from '../../../lib/styling'
import Select from '../../../components/basic/Select'

import RadioButtons from '../RadioButtons/index'
import Checkbox from '../Checkbox/index'
import { Input } from './index'
import EnhancedDatePicker from '../EnhancedDatePicker'
import baseStyles from './Fields.scss'
import ReCaptchaForm from '../ReCaptchaForm'
import messages from './messages'

type Props ={
  className?: ?string,
  wrapperClassName?: ?string,
  input: Object,
  componentProps?: Object,
  meta: Object,
  styles: Object,
  label?: ?string,
  reverseLabel?: ?boolean,
  children: any,
  hideError?: boolean,
  required?: boolean
}

export const FieldError = injectIntl(applyStyling(baseStyles)(
  ({ meta: { dirty, submitFailed, error }, styles, intl: { formatMessage } }: Object) =>
    (dirty || submitFailed) && error ? (
      <div className={styles.inputError}>
        {messages[error] ? formatMessage(messages[error]) : error}
      </div>
    ) : null
))

export const FieldComponentWrapper = ({
  className, meta, styles, label, reverseLabel, children, required, hideError
}: Props) => {
  const labelContent = label && (
    <label className="control-label">
      { label }{ required ? <span>*</span> : null }
    </label>
  )

  return (
    <div className={ classNames(className, {
      'has-error': (meta.dirty || meta.submitFailed) && !meta.valid,
      'has-success': meta.valid,
      'is-loading': meta.asyncValidating
    })}
    >
      {!reverseLabel && labelContent}
      { children }
      {reverseLabel && labelContent}
      {!hideError && <FieldError meta={ meta } styles={ styles }/>}
    </div>
  )
}
export const wrapFieldComponent = (InputComponent: any, defaultProps: Object = {}) => {
  const innerFieldComponent = ({
    wrapperClassName, input, styles, meta, label,
    reverseLabel, required, hideError, componentProps = {}, ...props }: Props
  ) => {
    const inputProps = { ...input, ...defaultProps, ...props }
    return defaultProps.generic ?
      <InputComponent
        { ...inputProps }
        { ...componentProps }
        styles={ styles }
        meta={meta}
      /> : (
        <FieldComponentWrapper
          className={wrapperClassName}
          input={input}
          meta={meta}
          label={label}
          required={required}
          styles={ styles }
          hideError={hideError}
          reverseLabel={reverseLabel}
        >
          <InputComponent
            { ...inputProps }
            { ...componentProps }
            meta={meta}
          />
        </FieldComponentWrapper>
      )
  }

  return innerFieldComponent
}

export const scrollToInvalid = (errors: Object) => {
  if (errors) {
    const invalidInput = Object.keys(errors).find(v => errors[v] !== undefined)
    if (invalidInput) {
      const elements = document.getElementsByName(invalidInput)
      if (elements.length > 0) {
        window.scrollTo(0, window.pageYOffset +
          (document.getElementsByName(invalidInput)[0].getBoundingClientRect().top - 100))
        document.getElementsByName(invalidInput)[0].focus()
      }
    }
  }
}

export const GenericSelectField = wrapFieldComponent(
  Select, { generic: true, simpleValue: true, clearable: false, searchable: false }
)
export const SelectField = wrapFieldComponent(Select, { simpleValue: true, clearable: false, searchable: false })
export const RadioButtonsField = wrapFieldComponent(RadioButtons, { })
export const GenericInputField = wrapFieldComponent(
  Input, { generic: true, simpleValue: true, clearable: false, searchable: false }
)
export const InputField = wrapFieldComponent(Input, { simpleValue: true, clearable: false, searchable: false })
export const CheckboxField = wrapFieldComponent(Checkbox, { simpleValue: true, clearable: false, searchable: false })
export const EnhancedDatePickerField = wrapFieldComponent(EnhancedDatePicker, { })
export const ReCaptchaField = wrapFieldComponent(ReCaptchaForm, {})
