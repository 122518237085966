// @flow
import React, { PureComponent } from 'react'
import { applyStyling } from '../../../lib/styling'
import ReCaptcha from '@fcse/tsbob/dist/components/ReCaptcha'
import baseStyles from './ReCaptchaForm.scss'

type Props = {
  styles: Object,
  meta: Object,
  input: Object,
  reCaptchaSiteKey: string,
  onChange: Function
}

class ReCaptchaForm extends PureComponent<Props> {
  ref: any

  componentDidUpdate(prevProps: Props): void {
    if (prevProps.meta && !prevProps.meta.submitFailed && this.props.meta.submitFailed
      && this.ref && this.ref.reCaptchaInstance) {
      this.ref.reCaptchaInstance.reset()
    }
  }

  render() {
    const { styles, onChange, reCaptchaSiteKey } = this.props
    return (
      <div className={styles.root}>
        <ReCaptcha
          refDeep={ref => this.ref = ref}
          siteKey={reCaptchaSiteKey}
          styles={styles.container}
          onChange={onChange}
        />
      </div>
    )
  }
}

export default applyStyling(baseStyles)(ReCaptchaForm)
