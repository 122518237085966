/** @flow */
import _ from 'lodash'
import { animateScroll } from 'react-scroll'

export const scrollToTarget = (scrollTarget: ?any, stickyId?: string) => {
  if (_.isNumber(scrollTarget)) {
    animateScroll.scrollTo(scrollTarget, { isDynamic: true, smooth: true })
    return
  }

  const element = document && scrollTarget ? (
    typeof scrollTarget === 'object' ? scrollTarget : document.getElementById(scrollTarget)
  ) : null
  const sticky = document && stickyId ? document.getElementById(stickyId) : null
  const scrollTop = window && document && document.documentElement ?
    window.pageYOffset || document.documentElement.scrollTop : 0
  if (element) {
    animateScroll.scrollTo(
      sticky ?
        element.getBoundingClientRect().top + scrollTop - sticky.clientHeight :
        element.getBoundingClientRect().top + scrollTop,
      { isDynamic: true, smooth: true }
    )
  }
}

export const scrollToMiddle = (elm: any) => {
  const elmOffset = getOffsetTop(elm)
  animateScroll.scrollTo(
    elmOffset - window.innerHeight / 4 + elm.offsetHeight / 2,
    {
      isDynamic: true,
      smooth: true,
      duration: 300,
      delay: 0
    }
  )
}

const getOffsetTop = ( el: any ) => {
  if (!window) {
    return 0
  }

  try {
    const rect = el.getBoundingClientRect()
    const scrollTop = window.pageYOffset || (document.documentElement ? document.documentElement.scrollTop : 0)
    return rect.top + scrollTop
  } catch (e) {
    return 0
  }
}
