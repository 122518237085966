// @flow
import React, { Component } from 'react'
import { withQuery } from '../../globalSearch'
import WithHandlersWrapper, { type WithGlobalSearchHandlersProps }
  from '../../components/GlobalSearch/WithHandlersWrapper'
import type { WithQueryProps } from '../../index'

export type WithGlobalSearchQueryWrapperProps = WithQueryProps & WithGlobalSearchHandlersProps & {
  onResetFilters: Function
}

const withQueryWrapper = (InnerComponent: any) => {
  class Wrapper extends Component<*> {
    onResetFilters = () => {
      this.props.reset()
    }


    setSearchValue = (value: Object) => {
      this.props.setStateValue({ ...this.props.values, ...value })
    }

    render() {
      const { values, updateStateValue, ...rest } = this.props
      return (
        <WithHandlersWrapper globalSearch={values} updateStateValue={updateStateValue}>
          {props => (
            <InnerComponent
              {...props}
              {...rest}
              updateStateValue={updateStateValue}
              setSearchValue={this.setSearchValue}
              onResetFilters={this.onResetFilters}
            />
          )}
        </WithHandlersWrapper>
      )
    }
  }
  return withQuery(Wrapper)
}

export default withQueryWrapper
