// @flow

import React, { useState, useCallback, useRef, useEffect } from 'react'
import classNames from 'classnames'
import useWindowScroll from '../../../../hooks/useWindowScroll'
import { compose } from 'recompose'
import { applyStyling } from '../../../../lib/styling'
import Device from '../../../../components/basic/Device'
import Tooltip from '../../../../components/basic/Tooltip'
import Search from '../../../../components/Search'
import baseStyles from './Subnav.scss'
import withQueryWrapper from '../../../../state/enchancers/globalSearch/withQueryWrapper'
import { withSearch } from '../../../../enhancers/withGlobalSearch'
import { shorten } from 'helpers/misc'
import { Link } from 'gatsby-plugin-intl'

type Props = {
  styles: Object,
  globalSearch: Object,
  isLoading: Boolean,
  meta: Object,
  onQueryChange: Function,
  createQueryParams: Function
}

const Subnav = ({ styles, globalSearch, onQueryChange, createQueryParams, meta, isLoading }: Props) => {
  const [ searchOpen, setSearchOpen ] = useState(false)
  const toggleSearch = useCallback(
    () => setSearchOpen(!searchOpen),
    [ searchOpen ]
  )

  const tooltipRef = useRef(null)
  const scrollState = useWindowScroll()

  useEffect(() => {
    if (tooltipRef && tooltipRef.current) {
      tooltipRef.current.handleClose()
    }
  }, [ scrollState.y ])

  useEffect(() => {
    if (!isLoading) {
      setSearchOpen(false)
    }
  }, [ isLoading ])

  const SearchComponent = (({ ...rest }: Object) => (
    <Search
      onQueryChange={onQueryChange}
      createQueryParams={createQueryParams}
      styles={[ styles.search, searchOpen && styles['is-open'] ]}
      searchOpen={searchOpen}
      withRedirect
      searchValues={globalSearch}
      onBtnClick={/* !searchOpen &&*/ toggleSearch}
      targetPage="suche"
      form="globalSearch"
      {...rest}
    />
  ))

  const headerItems = meta?.header || []
  const desktopLabel = headerItems.find(item => item.title === 'desktop_label')
  const mobileLabel = headerItems.find(item => item.title === 'mobile_label')
  const phone = headerItems.find(item => item.title === 'phone')

  return (
    <nav className={styles.root}>
      <ul className={styles.navList}>
        <li className={styles.navItem}>
          <Device tablet desktop>
            <span className={styles.navLabel} dangerouslySetInnerHTML={{ __html: desktopLabel?.content || '' }}/>
          </Device>
          <Device phone>
            <Tooltip
              content={<span dangerouslySetInnerHTML={{ __html: mobileLabel?.content || '' }}/>}
              placement="bottom-end"
              trigger="click"
              styles={styles.tooltip}
              rootClose={true}
              refDeep={tooltipRef}
            >
              <span className={classNames(styles.navIcon, 'icon-clock-1')}/>
            </Tooltip>
          </Device>
        </li>
        <li className={classNames(styles.navItem, styles.navItemSearch)}>
          <Link to={'/suche'} className={styles.navLink}>
            <span className={classNames(styles.navIcon, 'icon-search')}/>
          </Link>
        </li>
        <li className={styles.navItem}>
          <a
            href={`tel: ${shorten(phone?.content || '', 999, true)}`}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.navLink}
          >
            <span className={classNames(styles.navIcon, 'icon-phone')}/>
            <span
              className={styles.navLabel}
              dangerouslySetInnerHTML={{ __html: shorten(phone?.content || '', 999, true) }}
            />
          </a>
        </li>
      </ul>

      <Device tablet desktop>
        <SearchComponent/>
      </Device>
    </nav>
  )
}

export default compose(
  withSearch,
  withQueryWrapper,
  applyStyling(baseStyles)
)(Subnav)
